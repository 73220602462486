import React, { memo } from 'react'
import netlifyIdentity from 'netlify-identity-widget'
import { connect } from 'react-redux'
import './Login.sass'

import { loginUser } from '../../actions'

netlifyIdentity.init()

const Login = ({ loginUser }) => {
  const handleLogin = () => {
    loginUser()
  }
  return (
    <main className="login">
      <div>
        <button onClick={handleLogin} >Log in</button>
      </div>
    </main>
  )
}

const mapDispatchToProps = {
  loginUser
}

export default connect(null, mapDispatchToProps)(memo(Login))
