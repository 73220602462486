import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { resetForm, getDocuments, deleteDocument, copyDocument, createInvoice } from '../../actions'

import './ListPage.sass'
import AddButton from '../../components/AddButton/AddButton'
import ListItem from './ListItem'
import DotLoader from '../../components/DotLoader/DotLoader'

class ListPage extends PureComponent {
  componentDidMount = () => {
    this.props.resetForm()
    this.props.getDocuments(this.props.currentType)
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.currentType !== prevProps.currentType) {
      this.props.resetForm()
      this.props.getDocuments(this.props.currentType)
    }
  }

  _handleSelectDoc = (id) => {
    const { history, currentType } = this.props
    history.push(`/${currentType}/update/${id}`)
  }

  _handleCreateInvoice = (id) => {
    const { history, createInvoice } = this.props
    const redirect = (newID) => {
      history.push(`/invoice/update/${newID}`)
    }
    createInvoice(id, redirect)
  }

  _handleCopyDoc = (id) => {
    const { history, currentType, copyDocument } = this.props
    const redirect = (newID) => {
      history.push(`/${currentType}/update/${newID}`)
    }
    copyDocument(id, redirect)
  }

  _handleDeleteDoc = (id) => {
    const { currentType, deleteDocument } = this.props
    deleteDocument(id, currentType)
  }

  _handleClick = () => {
    const { history, currentType } = this.props
    history.push(`/${currentType}/create`)
  }

  _renderItems = () => {
    const { documents, currentType } = this.props
    if (documents.loading || documents.copying) return <DotLoader />
    return (
      <ul className="list_items">
        <li className="list_items_headers">
          <div className="list_items_header">資料名</div>
          <div className="list_items_header">{ currentType === 'payment' ? 'お支払い先' : 'お客様名' }</div>
          <div className="list_items_header">合計金額</div>
          <div className="list_items_header">最終更新日</div>
          <div className="list_items_header actions"></div>
        </li>

        { documents.data.map(doc => (
          <ListItem
            doc={doc} key={doc.id}
            handleSelectDoc={this._handleSelectDoc}
            handleCreateInvoice={ currentType === 'estimate' ? this._handleCreateInvoice : null }
            handleCopyDoc={this._handleCopyDoc}
            handleDeleteDoc={this._handleDeleteDoc}
          />
        ))}
      </ul>
    )
  }

  render () {
    const { currentType } = this.props
    if (!currentType) return null
    let pageTitle = ''
    switch (currentType) {
      case 'estimate':
        pageTitle = 'お見積書'
        break
      case 'invoice':
        pageTitle = 'ご請求書'
        break
      case 'delivery':
        pageTitle = '納品書'
        break
      default:
        break
    }
    return (
      <div className="list_page">
        <h1 className="list_title text-center">{pageTitle}</h1>
        <AddButton handleClick={this._handleClick}/>
        { this._renderItems() }
      </div>
    )
  }
}

const mapStateToProps = ({ documents }) => ({
  documents
})

const mapDispatchToProps = {
  resetForm,
  getDocuments,
  deleteDocument,
  copyDocument,
  createInvoice
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPage)

ListPage.propTypes = {
  currentType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  documents: PropTypes.object.isRequired,
  getDocuments: PropTypes.func.isRequired,
  copyDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired
}
ListPage.defaultProps = {

}
