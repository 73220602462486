import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MAINA_INFO, FABIEN_INFO, USER_FABIEN } from '../../../constants'

import addressImg from '../../../assets/img/svg/address.svg'
import phoneImg from '../../../assets/img/svg/phone.svg'
import emailImg from '../../../assets/img/svg/email.svg'
import './DocumentFooter.sass'

const DocumentFooter = () => {
  const email = useSelector(state => state?.auth?.user?.email)
  const [config, setConfig] = useState(MAINA_INFO)

  useEffect(() => {
    if (email === USER_FABIEN) setConfig(FABIEN_INFO)
  }, [email])

  return (
    <footer>
      {
        config.image
          ? (
            <div className="flex justify-end">
              <div className="img_container">
                <img src={config.image} alt="mynatime logo"/>
              </div>
            </div>
            )
          : null
      }
      <div className="flex contact">
        <div className="item flex">
          <div className="item_image">
            <img src={addressImg} alt=""/>
          </div>
          <div className="item_content">{ config.address }</div>
        </div>
        <div className="item flex align-center">
          <div className="item_image">
            <img src={phoneImg} alt=""/>
          </div>
          <div className="item_content">{ config.phone }</div>
        </div>
        <div className="item flex align-center">
          <div className="item_image">
            <img src={emailImg} alt=""/>
          </div>
          <div className="item_content">{ config.mailAddress }</div>
        </div>
      </div>
    </footer>
  )
}
export default memo(DocumentFooter)
