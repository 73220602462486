import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './TableRow.sass'
import { formatNumber } from '../../../constants'

const TableRow = ({ item }) => {
  const { name, rate, quantity } = item
  return (
    <div className="table_row flex">
      <div className="item column accent_font_jp">{name}</div>
      <div className="rate column small_column">{formatNumber(rate)}</div>
      <div className="quantity column small_column">{formatNumber(quantity)}</div>
      <div className="price column small_column">{formatNumber(Number(rate) * Number(quantity))}</div>
    </div>
  )
}
export default memo(TableRow)

TableRow.propTypes = {
  item: PropTypes.object.isRequired
}
TableRow.defaultProps = {

}
