import CryptoJS from 'crypto-js'

const SECRET_KEY = 'some random string (might be good to store in .env)'

export const GUID = function () {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export const encrypt = function (messageToEncrypt) {
  return CryptoJS.AES.encrypt(messageToEncrypt, SECRET_KEY).toString()
}

export const decrypt = function (encryptedMessage) {
  const bytes = CryptoJS.AES.decrypt(encryptedMessage, SECRET_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}

// Prevent log in production
export const noop = function () {
  if (process.env.REACT_APP_STAGE === 'production') {
    console.log = noop
    console.warn = noop
    console.error = noop
  }
}

export const _handleImageChange = (image, maxSize = 2) => {
  const sizeLimit = maxSize * 1000000
  if (image) {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      // Check file type
      const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
      if (!acceptedTypes.includes(image.type)) {
        reject(new Error('画像ファイル外を選択しました。\n確認の上でもう一度画像ファイルを選択してください。'))
      }

      // Check file size
      if (image.size > sizeLimit) {
        reject(new Error(`画像ファイルは${maxSize}MBを超えています。\n確認の上でもう一度画像ファイルを選択してください。`))
      }
      reader.onerror = () => {
        reject(new DOMException('Problem parsing input file.'))
      }

      reader.onload = () => {
        resolve({
          image,
          imageUrl: reader.result
        })
      }
      reader.readAsDataURL(image)
    })
  }
}

export const _handleFileChange = (file, type, maxSize = 20) => {
  const sizeLimit = maxSize * 1000000
  let acceptedTypes
  switch (type) {
    case 'pdf':
      acceptedTypes = ['application/pdf']
      break
    case 'doc':
      acceptedTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.ms-word.document.macroenabled.12']
      break
    case 'xls':
      acceptedTypes = ['application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroenabled.12', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      break
    case 'zip':
      acceptedTypes = ['application/zip']
      break
    case 'csv':
      acceptedTypes = ['text/csv', 'application/vnd.ms-excel']
      break
    default:
      break
  }
  if (file) {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      console.log('file type', file)
      // Check file type
      if (!acceptedTypes.includes(file.type)) {
        reject(new Error('サポートされているファイル外を選択しました。\n確認の上でもう一度ファイルを選択してください。'))
      }

      // Check file size
      if (file.size > sizeLimit) {
        reject(new Error(`ファイルは${maxSize}MBを超えています。\n確認の上でもう一度ファイルを選択してください。`))
      }
      reader.onerror = () => {
        reject(new DOMException('Problem parsing input file.'))
      }

      reader.onload = () => {
        resolve({
          file,
          fileUrl: reader.result
        })
      }
      reader.readAsDataURL(file)
    })
  }
}

// Log to console content of form-data
export const formDataLog = (formData) => {
  for (const pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1])
  }
}
