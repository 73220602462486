import React, { memo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './DocumentRemarks.sass'

const DocumentRemarks = ({ remarks }) => {
  if (!remarks) return null
  return (
    <section className="remarks">
      <h4>備考</h4>
      <div className="remarks_content">{remarks}</div>
    </section>
  )
}

const mapStateToProps = ({ form }) => ({
  remarks: form.remarks
})

export default connect(mapStateToProps)(memo(DocumentRemarks))

DocumentRemarks.propTypes = {
  remarks: PropTypes.string
}
DocumentRemarks.defaultProps = {
  remarks: ''
}
