import netlifyIdentity from 'netlify-identity-widget'
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER
} from './types'
import { LOCAL_STORAGE_NAME } from '../constants'
import { encrypt, decrypt } from '../utils/helpers'

const storeUser = (user) => {
  console.log('user', user)
  const {
    app_metadata, created_at, confirmed_at, email, id, user_metadata
  } = user
  localStorage.setItem(
    LOCAL_STORAGE_NAME,
    encrypt(JSON.stringify({ ...app_metadata, created_at, confirmed_at, email, id, ...user_metadata }))
  )
}

const getUser = () => {
  const cryptedUser = localStorage.getItem(LOCAL_STORAGE_NAME)
  const user = cryptedUser && decrypt(cryptedUser)
  return user && JSON.parse(user)
}

export const checkAuth = () => dispatch => {
  dispatch({ type: LOGIN_USER })
  const user = getUser()
  if (user) {
    dispatch({ type: LOGIN_USER_SUCCESS, payload: netlifyIdentity.currentUser() })
  } else {
    dispatch(logoutUser)
  }
  // else if (netlifyIdentity && netlifyIdentity.currentUser()) {
  //   storeUser(netlifyIdentity.currentUser())
  //   dispatch({ type: LOGIN_USER_SUCCESS, payload: netlifyIdentity.currentUser() })
  // }
}

export const loginUser = () => dispatch => {
  netlifyIdentity.open()
  netlifyIdentity.on('login', user => {
    storeUser(user)
    dispatch({ type: LOGIN_USER_SUCCESS, payload: user })
  })

  netlifyIdentity.on('error', err => {
    console.error('Error', err)
    dispatch({ type: LOGIN_USER_FAIL, payload: err })
  })
}

export const logoutUser = () => {
  netlifyIdentity.logout()
  localStorage.removeItem(LOCAL_STORAGE_NAME)
  netlifyIdentity.on('logout', () => console.log('Logged out'))
  return { type: LOGOUT_USER }
}
