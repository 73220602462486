import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './TextInput.sass'

const TextInput = ({ label, value, name, index, suffix, handleChange, className, ...rest }) => {
  const _handleChange = (e) => {
    handleChange(name, e.target.value, index)
  }

  const isEmptyClass = !value || value.length === 0 ? 'empty' : ''
  return (
    <div className="textinput_container">
      <input
        id={`${name}_${index}`}
        className={ `textarea ${isEmptyClass} ${className}` }
        name={name}
        onChange={_handleChange}
        value={ value ? String(value) : '' }
        { ...rest }
      />
      {
        suffix ? <div className="suffix">{suffix}</div> : null
      }
      <div className="label_container">
        <label htmlFor={`${name}_${index}`}>{ label }</label>
      </div>
    </div>
  )
}
export default memo(TextInput)

TextInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number
}
TextInput.defaultProps = {
  type: 'text'
}
