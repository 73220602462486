import React, { memo } from 'react'

import DocumentHeader from '../../components/Document/DocumentHeader'
import Table from '../../components/Table'
import DocumentRemarks from '../../components/Document/DocumentRemarks'
import DocumentFooter from '../../components/Document/DocumentFooter'

import './PrintPayment.sass'

const PrintPayment = () => {
  return (
    <section className="page payment">
      <DocumentHeader type="payment"/>
      <div className="main_content">
        <Table />
      </div>
      <DocumentRemarks />
      <DocumentFooter />
    </section>
  )
}
export default memo(PrintPayment)
