import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './Checkbox.sass'

const Checkbox = ({ label, checked, name, handleChange, ...rest }) => {
  const _handleChange = (e) => {
    handleChange(name, e.target.checked)
  }
  return (
    <div className="checkbox_container">
      <input
        id={`${name}`}
        type="checkbox"
        name={name}
        onChange={_handleChange}
        checked={checked}
        { ...rest }
      />
      <div className="label_container">
        <label htmlFor={`${name}`}>
          <span className="label">{ label }</span>
          <div className="checkbox">
            <span className="checked">✔</span>
          </div>
        </label>
      </div>
    </div>
  )
}
export default memo(Checkbox)

Checkbox.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
}
