// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'
import 'firebase/firestore'
import { toast } from 'react-toastify'

import {
  READ_DOCUMENT,
  READ_DOCUMENT_SUCCESS,
  READ_DOCUMENT_FAIL,
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_SUCESS,
  SAVE_DOCUMENT_FAIL,
  RESET_DOCUMENT,
  INIT_FORM,
  GET_DOCUMENTS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,
  COPY_DOCUMENT,
  COPY_DOCUMENT_SUCESS,
  COPY_DOCUMENT_FAIL,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCESS,
  DELETE_DOCUMENT_FAIL,
  RESET_DOCUMENTS
} from './types'

import { FIREBASE_CONFIG, COLLECTIONS, DOCUMENT_TYPE } from '../constants'
import { GUID } from '../utils/helpers'

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(FIREBASE_CONFIG)
}
const db = firebase.firestore()

const getDocument = async (id) => {
  if (id) {
    try {
      const doc = await db.collection(COLLECTIONS.documents).doc(id).get()
      if (doc.exists) {
        return { id, ...doc.data() }
      } else {
        console.log('No such document!')
        throw new Error('No such document!')
      }
    } catch (error) {
      console.log('Error getting document:', error)
      throw error
    }
  }
}

const addIdToItems = (items) => {
  if (!items) return
  return items.map(item => {
    if (!item.id) {
      item.id = GUID()
    }
    return item
  })
}

export const getDocuments = (type) => async dispatch => {
  dispatch({ type: GET_DOCUMENTS })
  try {
    const data = []
    const querySnapshot = await db.collection(COLLECTIONS.documents)
      .where('deleted', '==', false)
      .where('type', '==', DOCUMENT_TYPE[type])
      .orderBy('lastUpdated', 'desc')
      .get()
    querySnapshot.forEach(function (doc) {
      data.push({ id: doc.id, ...doc.data() })
    })
    dispatch({ type: GET_DOCUMENTS_SUCCESS, payload: data })
  } catch (error) {
    console.log('Error getting documents: ', error)
    dispatch({ type: GET_DOCUMENTS_FAIL, payload: error })
  }
}

export const readDocument = (id) => async dispatch => {
  dispatch({ type: READ_DOCUMENT })
  try {
    const doc = await getDocument(id)
    dispatch({ type: READ_DOCUMENT_SUCCESS })
    dispatch({
      type: INIT_FORM,
      payload: {
        ...doc,
        items: addIdToItems(doc.items)
      }
    })
  } catch (error) {
    dispatch({ type: READ_DOCUMENT_FAIL, payload: error })
  }
}

export const saveDocument = (data, type) => async dispatch => {
  dispatch({ type: SAVE_DOCUMENT })
  const {
    id,
    documentName,
    clientName,
    clientTitle,
    documentID,
    documentDate,
    subject,
    expirationDate,
    items,
    remarks,
    subtotal,
    tax,
    total,
    noTax,
    taxRate
  } = data
  try {
    if (!id) {
      // Create document
      const docRef = await db.collection(COLLECTIONS.documents).add({
        type: DOCUMENT_TYPE[type],
        documentName,
        clientName,
        clientTitle,
        documentID,
        documentDate,
        subject,
        expirationDate,
        items: addIdToItems(items),
        remarks,
        subtotal,
        tax,
        total,
        noTax,
        taxRate,
        deleted: false,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
      if (docRef && docRef.id) {
        const doc = await getDocument(docRef.id)
        dispatch({ type: SAVE_DOCUMENT_SUCESS })
        dispatch({ type: INIT_FORM, payload: doc })
        toast.success('正常に保存しました')
      } else {
        dispatch({ type: SAVE_DOCUMENT_FAIL, payload: 'No id created...' })
        toast.error('保存失敗しました')
      }
    } else {
      // Update document
      const docRef = await db.collection(COLLECTIONS.documents).doc(id)
      await docRef.set({
        type: DOCUMENT_TYPE[type],
        documentName,
        clientName,
        clientTitle,
        documentID,
        documentDate,
        subject,
        expirationDate,
        items: addIdToItems(items),
        remarks,
        subtotal,
        tax,
        total,
        noTax,
        taxRate,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true })
      const doc = await getDocument(id)
      if (!doc.lastUpdated) { doc.lastUpdated = { toDate: () => new Date() } }
      dispatch({ type: SAVE_DOCUMENT_SUCESS })
      dispatch({ type: INIT_FORM, payload: doc })
      toast.success('正常に保存しました')
    }
  } catch (error) {
    console.log('err', error)
    dispatch({ type: SAVE_DOCUMENT_FAIL, payload: error })
    toast.error('保存失敗しました')
  }
}

export const copyDocument = (id, redirect) => async dispatch => {
  dispatch({ type: COPY_DOCUMENT })
  try {
    const doc = await getDocument(id)
    const {
      type,
      documentName,
      clientName,
      clientTitle,
      documentID,
      documentDate,
      subject,
      expirationDate,
      items,
      remarks,
      subtotal,
      tax,
      total,
      deleted
    } = doc
    const docRef = await db.collection(COLLECTIONS.documents).add({
      type,
      documentName,
      clientName,
      clientTitle,
      documentID,
      documentDate,
      subject,
      expirationDate,
      items: addIdToItems(items),
      remarks,
      subtotal,
      tax,
      total,
      deleted,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      created: firebase.firestore.FieldValue.serverTimestamp()
    })
    if (docRef && docRef.id) {
      const doc = await getDocument(docRef.id)
      dispatch({ type: COPY_DOCUMENT_SUCESS })
      dispatch({ type: INIT_FORM, payload: doc })
      if (redirect) redirect(docRef.id)
      toast.info('正常にコピーしました')
    } else {
      dispatch({ type: COPY_DOCUMENT_FAIL, payload: 'No id created...' })
      toast.error('コピー失敗しました')
    }
  } catch (error) {
    dispatch({ type: COPY_DOCUMENT_FAIL })
    toast.error('コピー失敗しました')
  }
}

export const deleteDocument = (id, type, redirect) => async dispatch => {
  dispatch({ type: DELETE_DOCUMENT })
  try {
    const docRef = await db.collection(COLLECTIONS.documents).doc(id)
    docRef.set({
      deleted: true,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true })
    dispatch(getDocuments(type))
    dispatch({ type: DELETE_DOCUMENT_SUCESS })
    if (redirect) redirect()
    toast.warning('正常に削除しました')
  } catch (error) {
    dispatch({ type: DELETE_DOCUMENT_FAIL })
    toast.error('削除失敗しました')
  }
}

export const createInvoice = (id, redirect) => async dispatch => {
  dispatch({ type: COPY_DOCUMENT })
  try {
    const doc = await getDocument(id)
    const {
      documentName,
      clientName,
      clientTitle,
      documentID,
      documentDate,
      subject,
      expirationDate,
      items,
      remarks,
      subtotal,
      tax,
      total,
      deleted
    } = doc
    const docRef = await db.collection(COLLECTIONS.documents).add({
      type: DOCUMENT_TYPE.invoice,
      documentName,
      clientName,
      clientTitle,
      documentID,
      documentDate,
      subject,
      expirationDate,
      items: addIdToItems(items),
      remarks,
      subtotal,
      tax,
      total,
      deleted,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      created: firebase.firestore.FieldValue.serverTimestamp()
    })
    if (docRef && docRef.id) {
      const doc = await getDocument(docRef.id)
      dispatch({ type: COPY_DOCUMENT_SUCESS })
      dispatch({ type: INIT_FORM, payload: doc })
      if (redirect) redirect(docRef.id)
      toast.info('正常に請求書作成しました')
    } else {
      dispatch({ type: COPY_DOCUMENT_FAIL, payload: 'No id created...' })
      toast.error('請求書作成失敗しました')
    }
  } catch (error) {
    dispatch({ type: COPY_DOCUMENT_FAIL })
    toast.error('請求書作成失敗しました')
  }
}

export const resetDocument = () => {
  return { type: RESET_DOCUMENT }
}
export const resetDocuments = () => {
  return { type: RESET_DOCUMENTS }
}
