import {
  INIT_FORM,
  UPDATE_FORM,
  ADD_FORM_ITEM,
  REMOVE_FORM_ITEM,
  UPDATE_FORM_ITEM,
  RESET_FORM
} from '../actions/types'
import { TAX } from '../constants'

const INITIAL_STATE = {
  id: null,
  lastUpdated: null,
  documentName: '',
  clientName: '',
  clientTitle: '',
  documentID: '',
  documentDate: '',
  subject: '', // not in delivery
  expirationDate: '', // not in delivery
  items: [],
  remarks: '', // not in delivery,
  subtotal: 0,
  tax: 0,
  total: 0,
  noTax: false,
  taxRate: TAX
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INIT_FORM:
      return { ...state, ...action.payload }
    case UPDATE_FORM: {
      const { name, value } = action.payload
      if (name === 'noTax') {
        const tax = value ? 0 : state.tax
        const taxValue = value ? 0 : state.subtotal * state.taxRate
        const total = state.subtotal + taxValue
        return {
          ...state,
          [name]: value,
          tax,
          total
        }
      }
      if (name === 'taxRate') {
        const tax = state.subtotal * value
        const total = state.subtotal + tax
        return {
          ...state,
          [name]: value,
          tax,
          total
        }
      }
      return { ...state, [name]: value }
    }
    case ADD_FORM_ITEM: {
      const items = [...state.items]
      items.push({
        name: '',
        rate: '',
        quantity: ''
      })
      return {
        ...state,
        items
      }
    }
    case REMOVE_FORM_ITEM: {
      // index = action.payload
      const items = state.items.filter((item, index) => {
        return index !== action.payload
      })

      let subtotal = 0
      for (const item of items) {
        const { rate, quantity } = item
        subtotal += Number(rate * quantity)
      }
      const tax = state.noTax ? 0 : subtotal * state.taxRate
      const total = subtotal + tax

      return {
        ...state,
        items,
        subtotal,
        tax,
        total
      }
    }
    case UPDATE_FORM_ITEM: {
      const { name, value, index } = action.payload
      const items = [...state.items]
      items[index][name] = value

      let subtotal = 0
      for (const item of items) {
        const { rate, quantity } = item
        subtotal += Number(rate * quantity)
      }
      const tax = state.noTax ? 0 : subtotal * state.taxRate
      const total = subtotal + tax

      return {
        ...state,
        items,
        subtotal,
        tax,
        total
      }
    }
    case RESET_FORM: return { ...state, ...INITIAL_STATE }
    default: return { ...state }
  }
}
