import React, { memo } from 'react'
import './TableHeader.sass'

const TableHeader = () => {
  return (
    <div className="table_header flex">
      <div className="table_header_item item">
        <div className="table_header_item_inner">
          <div className="english_title accent_font">Item</div>
          <div className="japanese_title accent_font_jp">品目</div>
        </div>
      </div>
      <div className="table_header_item rate">
        <div className="table_header_item_inner">
          <div className="english_title accent_font">Rate</div>
          <div className="japanese_title accent_font_jp">単価</div>
        </div>
      </div>
      <div className="table_header_item quantity">
        <div className="table_header_item_inner">
          <div className="english_title accent_font">Quantity</div>
          <div className="japanese_title accent_font_jp">数量</div>
        </div>
      </div>
      <div className="table_header_item price">
        <div className="table_header_item_inner">
          <div className="english_title accent_font">Price</div>
          <div className="japanese_title accent_font_jp">価格</div>
        </div>
      </div>
    </div>
  )
}
export default memo(TableHeader)
