import {
  READ_DOCUMENT,
  READ_DOCUMENT_SUCCESS,
  READ_DOCUMENT_FAIL,
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_SUCESS,
  SAVE_DOCUMENT_FAIL,
  RESET_DOCUMENT
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  saving: false,
  error: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case READ_DOCUMENT: return { ...state, loading: true, error: null }
    case READ_DOCUMENT_SUCCESS: return { ...state, loading: false, error: false }
    case READ_DOCUMENT_FAIL: return { ...state, loading: false, error: true }
    case SAVE_DOCUMENT: return { ...state, saving: true, error: null }
    case SAVE_DOCUMENT_SUCESS: return { ...state, saving: false }
    case SAVE_DOCUMENT_FAIL: return { ...state, saving: false, error: action.payload }
    case RESET_DOCUMENT: return { ...state, ...INITIAL_STATE }
    default: return { ...state }
  }
}
