export const LOGIN_USER = 'login_user'
export const LOGIN_USER_SUCCESS = 'login_user_success'
export const LOGIN_USER_FAIL = 'login_user_fail'
export const LOGOUT_USER = 'logout_user'

export const READ_DOCUMENT = 'read_document'
export const READ_DOCUMENT_SUCCESS = 'read_document_success'
export const READ_DOCUMENT_FAIL = 'read_document_fail'
export const SAVE_DOCUMENT = 'save_document'
export const SAVE_DOCUMENT_SUCESS = 'save_document_sucess'
export const SAVE_DOCUMENT_FAIL = 'save_document_fail'
export const COPY_DOCUMENT = 'copy_document'
export const COPY_DOCUMENT_SUCESS = 'copy_document_sucess'
export const COPY_DOCUMENT_FAIL = 'copy_document_fail'
export const DELETE_DOCUMENT = 'save_document'
export const DELETE_DOCUMENT_SUCESS = 'save_document_sucess'
export const DELETE_DOCUMENT_FAIL = 'save_document_fail'
export const RESET_DOCUMENT = 'reset_document'

export const GET_DOCUMENTS = 'get_documents'
export const GET_DOCUMENTS_SUCCESS = 'get_documents_success'
export const GET_DOCUMENTS_FAIL = 'get_documents_fail'
export const RESET_DOCUMENTS = 'reset_documents'

export const INIT_FORM = 'init_form'
export const UPDATE_FORM = 'update_form'
export const ADD_FORM_ITEM = 'add_form_item'
export const REMOVE_FORM_ITEM = 'remove_form_item'
export const UPDATE_FORM_ITEM = 'update_form_item'
export const RESET_FORM = 'reset_form'
