import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class PickerInput extends PureComponent {
  _renderIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <path className="no_fill" d="M0,0H30V30H0Z"/>
        <path d="M3.75,9.38V26.25h22.5V9.38ZM24.38,3.75h3.75A1.77,1.77,0,0,1,30,5.41a.81.81,0,0,1,0,.22v22.5A1.77,1.77,0,0,1,28.34,30a.74.74,0,0,1-.21,0H1.88A1.77,1.77,0,0,1,0,28.34V5.63A1.78,1.78,0,0,1,1.66,3.75h4V1.88A1.77,1.77,0,0,1,7.29,0H7.5A1.78,1.78,0,0,1,9.38,1.66V3.75H20.63V1.88a1.88,1.88,0,1,1,3.75,0ZM22.5,22.5H18.75V18.75H22.5Zm-5.62,0H13.13V18.75h3.75Zm5.62-5.62H18.75V13.13H22.5Zm-5.62,0H13.13V13.13h3.75ZM11.25,22.5H7.5V18.75h3.75Z"/>
      </svg>
    )
  }

  render () {
    const { onClick } = this.props
    return (
      <button
        type="button"
        onClick={onClick}
        className="btn datepicker"
      >
        {this._renderIcon()}
      </button>
    )
  }
}

PickerInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}
PickerInput.defaultProps = {
  onClick: () => {},
  value: ''
}
