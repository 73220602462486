import React, { memo } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { formatNumber } from '../../constants'
import Button from '../../components/Button/Button'

const ListItem = ({ doc, handleSelectDoc, handleCopyDoc, handleDeleteDoc, handleCreateInvoice }) => {
  const { documentName, clientName, clientTitle, total, lastUpdated } = doc
  const _handleClick = () => {
    handleSelectDoc(doc.id)
  }
  const _handleInvoice = (e) => {
    e.stopPropagation()
    if (window.confirm('請求しますか？')) {
      handleCreateInvoice(doc.id)
    }
  }
  const _handleCopy = (e) => {
    e.stopPropagation()
    if (window.confirm('コピーしますか？')) {
      handleCopyDoc(doc.id)
    }
  }
  const _handleDelete = (e) => {
    e.stopPropagation()
    if (window.confirm('削除しますか？')) {
      handleDeleteDoc(doc.id)
    }
  }
  return (
    <li className="list_item" onClick={_handleClick}>
      <div className="list_item_info">{documentName}</div>
      <div className="list_item_info">{clientName} {clientTitle}</div>
      <div className="list_item_info">{formatNumber(total)}円</div>
      <div className="list_item_info">{moment(lastUpdated.toDate()).format('YYYY/MM/DD HH:mm')}</div>
      <div className="list_item_info actions">
        { handleCreateInvoice ? <Button onClick={_handleInvoice} label="請求" className="primary"/> : null }
        <Button onClick={_handleCopy} label="コピー"/>
        <Button onClick={_handleDelete} className="accent" label="削除"/>
      </div>
    </li>
  )
}

export default memo(ListItem)

ListItem.propTypes = {
  doc: PropTypes.object.isRequired,
  handleSelectDoc: PropTypes.func.isRequired,
  handleCreateInvoice: PropTypes.func,
  handleCopyDoc: PropTypes.func.isRequired,
  handleDeleteDoc: PropTypes.func.isRequired
}
