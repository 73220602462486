import React, { memo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './TableTotal.sass'
import { formatNumber } from '../../../constants'

const TableTotal = ({ subtotal, tax, total, noTax }) => {
  return (
    <div className="table_total">
      {
        noTax
          ? null
          : (
            <>
              <div className="subtotal flex">
                <h4>SUBTOTAL
                  <span>小計</span>
                </h4>
                <div className="subtotal_amount"><span className="number">{formatNumber(subtotal)}</span>円</div>
              </div>
              <div className="tax flex">
                <h4>TAX
                  <span>消費税</span>
                </h4>
                <div className="tax_amount flex"><span className="number">{formatNumber(tax)}</span>円</div>
              </div>
            </>
            )
      }
      <div className="total_row flex">
        <div className="table_header_item total_title">
          <div className="table_header_item_inner">
            <div className="english_title">Total</div>
            <div className="japanese_title">合計</div>
          </div>
        </div>
        <div className="table_header_item total_number">
          <div className="table_header_item_inner">
            <div className="total_number_content"><span>{formatNumber(total)}</span>円</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ form }) => ({
  noTax: form.noTax,
  subtotal: form.subtotal,
  tax: form.tax,
  total: form.total
})

export default connect(mapStateToProps)(memo(TableTotal))

TableTotal.propTypes = {
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}
TableTotal.defaultProps = {

}
