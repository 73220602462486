import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableTotal from './TableTotal'

import './Table.sass'

class Table extends PureComponent {
  _renderRows = () => {
    return this.props.items.map((item, index) => {
      return <TableRow item={item} key={index}/>
    })
  }

  render () {
    return (
      <div className="table">
        <TableHeader />
        { this._renderRows() }
        <TableTotal />
      </div>
    )
  }
}

const mapStateToProps = ({ form }) => ({
  items: form.items
})

export default connect(mapStateToProps)(Table)

Table.propTypes = {
  items: PropTypes.array.isRequired
}
Table.defaultProps = {

}
