import React, { memo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './DocumentHeader.sass'
import { formatNumber } from '../../../constants'

const DocumentHeader = ({ type, form }) => {
  const {
    clientName,
    clientTitle,
    documentID,
    documentDate,
    subject,
    total,
    expirationDate
  } = form
  let title, subtitle, idLabel, dateLabel
  switch (type) {
    case 'estimate':
      title = 'Estimate'
      subtitle = 'お見積り書'
      idLabel = '見積書番号'
      dateLabel = '発行日'
      break
    case 'invoice':
      title = 'Invoice'
      subtitle = 'ご請求書'
      idLabel = '請求書番号'
      dateLabel = '発行日'
      break
    case 'delivery':
      title = 'Delivery'
      subtitle = '納品書'
      idLabel = '納品書番号'
      dateLabel = '納品日'
      break
    case 'payment':
      title = 'Payment'
      subtitle = 'お支払い明細書'
      idLabel = '明細書番号'
      dateLabel = 'お支払い日'
      break
    default:
      title = ''
      subtitle = ''
      idLabel = ''
      dateLabel = ''
      break
  }

  const _renderDocumentObject = () => {
    switch (type) {
      case 'estimate':
        return (
          <div className="subject_block">
            <div className="subject">件名：<span>{subject}</span></div>
            <div className="amount">お見積金額：<span>{formatNumber(total)}</span><span>円</span></div>
            <div className="expiration">有効期限：<span>{expirationDate}</span></div>
          </div>
        )
      case 'invoice':
        return (
          <div className="subject_block">
            <div className="subject">件名：<span>{subject}</span></div>
            <div className="amount">ご請求金額：<span>{formatNumber(total)}</span><span>円</span></div>
            <div className="expiration">お支払い期限：<span>{expirationDate}</span></div>
          </div>
        )
      case 'delivery':
        return (
          <div className="subject_block">
            <div className="subject">下記の通り、納品いたします。</div>
            <div className="amount">合計金額：<span>{formatNumber(total)}</span><span>円</span></div>
          </div>
        )
      case 'payment':
        return (
          <div className="subject_block">
            <div className="subject">
              今月もお疲れさまでした！
              <br />
              下記の通り、お支払いいたします。
            </div>
            <div className="amount">合計金額：<span>{formatNumber(total)}</span><span>円</span></div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="document_header">
      <div className="top flex justify-between">
        <div className="left">
          <h1 className="accent_font">{ title }</h1>
          <h2 className="accent_font_jp">{ subtitle }</h2>
        </div>
        <div className="right">
          <div className="id flex justify-between"><span className="label ">{ idLabel }：</span><span className="value">{documentID}</span></div>
          <div className="date flex justify-between"><span className="label ">{ dateLabel }：</span><span className="value">{documentDate}</span></div>
        </div>
      </div>
      <div className="client">{clientName} {clientTitle}</div>
      { _renderDocumentObject() }
    </div>
  )
}

const mapStateToProps = ({ form }) => ({
  form
})
export default connect(mapStateToProps)(memo(DocumentHeader))

DocumentHeader.propTypes = {
  type: PropTypes.oneOf(['estimate', 'invoice', 'delivery', 'payment']).isRequired,
  form: PropTypes.object.isRequired
}
DocumentHeader.defaultProps = {

}
