import React, { memo, Fragment, useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Header from '../components/Header'
import ListPage from '../pages/ListPage'
import CreateDocument from '../pages/CreateDocument'
import PrintEstimate from '../pages/PrintEstimate'
import PrintInvoice from '../pages/PrintInvoice'
import PrintDelivery from '../pages/PrintDelivery'
import PrintPayment from '../pages/PrintPayment'
import Home from '../pages/Home'
import Login from '../pages/Login'

import '../styles/main.sass'
import { checkAuth } from '../actions'

const Router = ({ isAuthenticated, checkAuth }) => {
  useEffect(() => {
    checkAuth()
  }, [checkAuth])
  const _renderPrivateRoutes = () => {
    return (
      <>
        <Header />
        <main className="app">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/estimate" exact render={(props) => <ListPage currentType="estimate" title="見積書" {...props}/>} />
            <Route path="/estimate/create" exact render={(props) => <CreateDocument currentType="estimate" title="見積書" {...props}/>} />
            <Route path="/estimate/update/:id" exact render={(props) => <CreateDocument currentType="estimate" title="見積書" {...props}/>} />
            <Route path="/estimate/print" exact component={PrintEstimate} />
            <Route path="/invoice" exact render={(props) => <ListPage currentType="invoice" title="請求書" {...props}/>} />
            <Route path="/invoice/create" exact render={(props) => <CreateDocument currentType="invoice" title="請求書" {...props}/>} />
            <Route path="/invoice/update/:id" exact render={(props) => <CreateDocument currentType="invoice" title="請求書" {...props}/>} />
            <Route path="/invoice/print" exact component={PrintInvoice} />
            <Route path="/delivery" exact render={(props) => <ListPage currentType="delivery" title="納品書" {...props}/>} />
            <Route path="/delivery/create" exact render={(props) => <CreateDocument currentType="delivery" title="納品書" {...props}/>} />
            <Route path="/delivery/update/:id" exact render={(props) => <CreateDocument currentType="delivery" title="納品書" {...props}/>} />
            <Route path="/delivery/print" exact component={PrintDelivery} />
            <Route path="/payment" exact render={(props) => <ListPage currentType="payment" title="明細書" {...props}/>} />
            <Route path="/payment/create" exact render={(props) => <CreateDocument currentType="payment" title="明細書" {...props}/>} />
            <Route path="/payment/update/:id" exact render={(props) => <CreateDocument currentType="payment" title="明細書" {...props}/>} />
            <Route path="/payment/print" exact component={PrintPayment} />
          </Switch>
        </main>
      </>
    )
  }
  return (
    <BrowserRouter>
      <Fragment>
        <ToastContainer autoClose={2000} toastClassName="custom-toast" position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar/>
        {
          isAuthenticated
            ? _renderPrivateRoutes()
            : <Login />
        }
      </Fragment>
    </BrowserRouter>
  )
}
const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated
})

const mapDispatchToProps = {
  checkAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Router))
