import React, { memo } from 'react'

import DocumentHeader from '../../components/Document/DocumentHeader'
import PaymentMethods from '../../components/PaymentMethods'
import Table from '../../components/Table'
import DocumentRemarks from '../../components/Document/DocumentRemarks'
import DocumentFooter from '../../components/Document/DocumentFooter'

import './PrintInvoice.sass'

const PrintInvoice = () => {
  return (
    <section className="page invoice">
      <DocumentHeader type="invoice"/>
      <div className="main_content flex">
        <PaymentMethods />
        <Table />
      </div>
      <DocumentRemarks />
      <DocumentFooter />
    </section>
  )
}

export default memo(PrintInvoice)
