import React, { PureComponent } from 'react'
import './DotLoader.sass'

export default class DotLoader extends PureComponent {
  render () {
    return (
      <div className="dot-loader flex align-center">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    )
  }
}
