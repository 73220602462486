import reduxThunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import configureAxios from './configureAxios'

import reducers from '../reducers'

const axiosInstance = configureAxios()

export default createStore(
  reducers,
  compose(
    applyMiddleware(reduxThunk.withExtraArgument(axiosInstance)),
    // window.devToolsExtension && process.env.REACT_APP_STAGE === 'dev' ? window.devToolsExtension() : f => f,
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
)
