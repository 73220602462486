import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'

import { updateForm, saveDocument, readDocument, copyDocument, deleteDocument } from '../../actions'

import CreateItems from './CreateItems'
import Button from '../../components/Button'
import Checkbox from '../../components/FormInputs/Checkbox'
import TextInput from '../../components/FormInputs/TextInput'
import TextArea from '../../components/FormInputs/TextArea'
import SelectInput from '../../components/FormInputs/SelectInput'
import DatePicker from '../../components/FormInputs/DatePicker'
import './CreateDocument.sass'
import DotLoader from '../../components/DotLoader/DotLoader'
import { Redirect } from 'react-router-dom'
import { TAX } from '../../constants'

class CreateDocument extends PureComponent {
  componentDidMount = () => {
    const { match, readDocument, currentType, updateForm } = this.props
    if (match.params.id) {
      readDocument(match.params.id)
    }
    if (currentType === 'payment') {
      updateForm('noTax', true)
    }
  }

  _handleSave = (e) => {
    e.preventDefault()
    const { form, currentType, saveDocument } = this.props
    if (!document.saving) {
      saveDocument(form, currentType)
    }
  }

  _handleCopy = () => {
    if (window.confirm('コピーしますか？')) {
      const { history, currentType, copyDocument, form } = this.props
      const redirect = (newID) => {
        history.push(`/${currentType}/update/${newID}`)
      }
      copyDocument(form.id, redirect)
    }
  }

  _handleDelete = () => {
    if (window.confirm('削除しますか？')) {
      const { currentType, deleteDocument, form, history } = this.props
      const redirect = () => {
        history.push(`/${currentType}`)
      }
      deleteDocument(form.id, currentType, redirect)
    }
  }

  _handlePrint = () => {
    const { history, currentType } = this.props
    history.push(`/${currentType}/print`)
  }

  _renderPrintIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248">
        <path d="M230.559,62.498h-27.785V17.133c0-4.143-3.358-7.5-7.5-7.5H52.785c-4.142,0-7.5,3.357-7.5,7.5v45.365H17.5c-9.649,0-17.5,7.85-17.5,17.5v96.225c0,9.649,7.851,17.5,17.5,17.5h27.785v37.203c0,4.143,3.358,7.5,7.5,7.5h142.488c4.142,0,7.5-3.357,7.5-7.5v-37.203h27.785c9.649,0,17.5-7.851,17.5-17.5V79.998C248.059,70.349,240.208,62.498,230.559,62.498zM60.285,24.633h127.488v37.865H60.285V24.633z M187.773,223.426H60.285v-74.404h127.488V223.426z M233.059,176.223c0,1.355-1.145,2.5-2.5,2.5h-27.785v-37.201c0-4.143-3.358-7.5-7.5-7.5H52.785c-4.142,0-7.5,3.357-7.5,7.5v37.201H17.5c-1.355,0-2.5-1.145-2.5-2.5V79.998c0-1.356,1.145-2.5,2.5-2.5h35.285h142.488h35.285c1.355,0,2.5,1.144,2.5,2.5V176.223z"/>
        <circle cx="195.273" cy="105.76" r="10.668"/>
        <path d="M158.151,163.822H89.907c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h68.244c4.142,0,7.5-3.357,7.5-7.5C165.651,167.18,162.294,163.822,158.151,163.822z"/>
        <path d="M158.151,193.623H89.907c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h68.244c4.142,0,7.5-3.357,7.5-7.5C165.651,196.98,162.294,193.623,158.151,193.623z"/>
      </svg>

    )
  }

  render () {
    const {
      currentType,
      title,
      form,
      document,
      updateForm,
      match
    } = this.props
    if (document.loading && match.params.id) return <DotLoader />
    if (document.error) return <Redirect to={`/${currentType}`} />

    const {
      id,
      lastUpdated,
      noTax,
      taxRate,
      documentName,
      clientName,
      clientTitle,
      documentID,
      documentDate,
      subject,
      expirationDate,
      remarks
    } = form

    return (
      <div className="create_document">
        <h1 className="text-center">{title}</h1>
        <div className="print_button">
          <Button label="印刷" handleClick={this._handlePrint} className="accent" icon={this._renderPrintIcon()}/>
        </div>
        {
          id ? <h4 className="id">id: {id}（{moment(lastUpdated.toDate()).format('YYYY/MM/DD HH:mm')}）</h4> : null
        }
        <form onSubmit={this._handleSave} className="form" id="create_form" action="">
          <Checkbox
            name="noTax"
            label="税なし"
            checked={noTax}
            handleChange={updateForm}
          />
          {
            noTax
              ? null
              : (
                <TextInput
                  type="number"
                  name="taxRate"
                  value={taxRate || TAX}
                  label="税率"
                  handleChange={updateForm}
                  className="taxRate"
                  required={!noTax}
                />
                )
          }

          <TextInput
            type="text"
            name="documentName"
            value={documentName}
            label="資料名"
            handleChange={updateForm}
            required
          />
          <div className="client flex">
            <TextInput
              type="text"
              name="clientName"
              value={clientName}
              label={currentType === 'payment' ? 'お支払い先' : 'お客様名'}
              handleChange={updateForm}
            />
            <SelectInput
              name="clientTitle"
              value={clientTitle}
              label="敬称"
              options={[
                { label: '御中', value: '御中' },
                { label: '様', value: '様' }
              ]}
              handleChange={updateForm}
            />
          </div>
          <TextInput
            type="text"
            name="documentID"
            value={documentID}
            label="番号"
            handleChange={updateForm}
          />
          <DatePicker
            name="documentDate"
            value={documentDate}
            label="日にち"
            handleChange={updateForm}
          />
          {
            currentType !== 'delivery'
              ? (
                <Fragment>
                  <TextInput
                    type="text"
                    name="subject"
                    value={subject}
                    label="件名"
                    handleChange={updateForm}
                  />
                  <DatePicker
                    name="expirationDate"
                    value={expirationDate}
                    label="期限"
                    handleChange={updateForm}
                  />
                </Fragment>
                )
              : null
          }

          <CreateItems />

          <TextArea
            label="備考"
            name="remarks"
            value={remarks}
            handleChange={updateForm}
            className="remarks_input"
          />

          <div className="buttons_container flex justify-end">
            <Button type="submit" label="保存" className="primary" disabled={document.saving}/>
            {
              id
                ? (
                <Fragment>
                  <Button label="コピー" handleClick={this._handleCopy} className="reverse_primary"/>
                  <Button label="削除" handleClick={this._handleDelete} className="danger"/>
                </Fragment>
                  )
                : null
            }
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ form, document }) => ({
  form,
  document
})

const mapDispatchToProps = {
  updateForm,
  saveDocument,
  readDocument,
  copyDocument,
  deleteDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocument)

CreateDocument.propTypes = {
  currentType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  readDocument: PropTypes.func.isRequired,
  copyDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired
}
CreateDocument.defaultProps = {

}
