import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './TextArea.sass'

class TextArea extends PureComponent {
  constructor (props) {
    super(props)
    this.textAreaRef = React.createRef()
  }

  _handleChange = (e) => {
    const { handleChange, name } = this.props
    // console.log(handleChange, name, e.target.value)
    handleChange(name, e.target.value)
  }

  _handleAreaResize = () => {
    const textarea = this.textAreaRef.current
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  render () {
    const { label, value, name, handleChange, className, ...rest } = this.props
    const isEmptyClass = !value || value.length === 0 ? 'empty' : ''
    return (
      <div className="textarea_container">
        <textarea
          id={ name }
          ref={this.textAreaRef}
          className={ `textarea ${isEmptyClass} ${className}` }
          label={ label }
          name={name}
          onChange={this._handleChange}
          onInput={this._handleAreaResize}
          value={value}
          { ...rest }
        ></textarea>
        <span className="label_container">
          <label htmlFor={name}>{ label }</label>
        </span>
      </div>
    )
  }
}
export default TextArea

TextArea.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}
TextArea.defaultProps = {

}
