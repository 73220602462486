import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,
  COPY_DOCUMENT,
  COPY_DOCUMENT_SUCESS,
  COPY_DOCUMENT_FAIL,
  RESET_DOCUMENTS
} from '../actions/types'

const INITIAL_STATE = {
  loading: null,
  copying: null,
  data: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DOCUMENTS: return { ...state, loading: true, error: null }
    case GET_DOCUMENTS_SUCCESS: return { ...state, loading: false, data: action.payload }
    case GET_DOCUMENTS_FAIL: return { ...state, loading: false, error: action.payload }
    case COPY_DOCUMENT: return { ...state, copying: true, error: null }
    case COPY_DOCUMENT_SUCESS: return { ...state, copying: false }
    case COPY_DOCUMENT_FAIL: return { ...state, copying: false, error: action.payload }
    case RESET_DOCUMENTS: return { ...state, ...INITIAL_STATE }
    default: return { ...state }
  }
}
