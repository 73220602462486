import React, { memo } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import './AddButton.sass'

const AddButton = ({ handleClick }) => {
  return (
    <div className="add_button" onClick={handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25"/>
        <line x1="25" y1="13" x2="25" y2="38"/>
        <line x1="37.5" y1="25" x2="12.5" y2="25"/>
      </svg>
    </div>
  )
}

export default withRouter(memo(AddButton))

AddButton.propTypes = {
  handleClick: PropTypes.func.isRequired
}
