import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './SelectInput.sass'

const SelectInput = ({ label, name, value, suffix, required, options, handleChange }) => {
  const _handleChange = (e) => {
    handleChange(name, e.target.value)
  }

  const _renderOptions = () => {
    return options.map(option => {
      return <option value={option.value} key={option.value}>{option.label}</option>
    })
  }

  return (
    <div className={`select_container flex ${required ? ' required' : ''}`}>
      <label className="select_label_container">
        <div className="select_label">{label}</div>
        <div className="select_inner_container">
          <select onChange={_handleChange} name={name} value={value} required={required}>
            <option value="">---</option>
            { _renderOptions() }
          </select>
        </div>
      </label>
      <span>{suffix}</span>
    </div>
  )
}
export default memo(SelectInput)

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  suffix: PropTypes.string,
  required: PropTypes.bool
}
SelectInput.defaultProps = {
  label: '',
  suffix: '',
  choiceLabelKey: '',
  choiceValueKey: '',
  required: false
}
