import { combineReducers } from 'redux'
import auth from './authReducer'
import form from './formReducer'
import documents from './documentsReducer'
import document from './documentReducer'

const rootReducer = combineReducers({
  auth,
  form,
  documents,
  document
})

export default rootReducer
