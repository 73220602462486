import React, { memo } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import './Header.sass'

import { logoutUser } from '../../actions'

const Header = ({ logoutUser }) => {
  const handleLogout = () => {
    if (window.confirm('ログアウトしますか？')) {
      logoutUser()
    }
  }
  return (
    <header className="no_print">
      <h1>MynaTime経理ツール</h1>
      <nav>
        <NavLink className="navLink" to="/" exact>ホーム</NavLink>
        <NavLink className="navLink" to="/estimate">お見積書</NavLink>
        <NavLink className="navLink" to="/invoice">ご請求書</NavLink>
        <NavLink className="navLink" to="/delivery">納品書</NavLink>
        <NavLink className="navLink" to="/payment">支払い明細書</NavLink>
        <button className="logoutBtn" onClick={handleLogout}>ログアウト</button>
      </nav>
    </header>
  )
}

const mapDispatchToProps = {
  logoutUser
}

export default connect(null, mapDispatchToProps)(memo(Header))
