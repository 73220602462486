import {
  ADD_FORM_ITEM,
  REMOVE_FORM_ITEM,
  UPDATE_FORM_ITEM,
  UPDATE_FORM,
  RESET_FORM
} from './types'

export const updateForm = (name, value) => {
  return { type: UPDATE_FORM, payload: { name, value } }
}
export const addFormItem = () => {
  return { type: ADD_FORM_ITEM }
}
export const deleteFormItem = (index) => {
  return { type: REMOVE_FORM_ITEM, payload: index }
}
export const updateFormItem = (name, value, index) => {
  return { type: UPDATE_FORM_ITEM, payload: { name, value, index } }
}

export const resetForm = () => {
  return { type: RESET_FORM }
}
