import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'

import { MAINA_BANK_ACCOUNTS, FABIEN_BANK_ACCOUNTS, USER_FABIEN } from '../../constants'

import './PaymentMethods.sass'

const PaymentMethods = () => {
  const email = useSelector(state => state?.auth?.user?.email)

  const _renderBankAccounts = () => {
    let BANK_ACCOUNTS = MAINA_BANK_ACCOUNTS
    if (email === USER_FABIEN) BANK_ACCOUNTS = FABIEN_BANK_ACCOUNTS
    return BANK_ACCOUNTS.map(account => {
      const { bankName, bankOffice, postAccountNumber, accountNumber, furigana, name, comment } = account
      return (
        <li className="bank" key={accountNumber}>
          <div className="bank_name_label label">銀行名：</div>
          <div className="bank_name_value value"><span className="accent_color">{ bankName }</span></div>
          <div className="account_number_label label">店番号：</div>
          <div className="account_number_value value">{ bankOffice }</div>
          <div className="account_number_label label">口座番号：</div>
          <div className="account_number_value value">{ accountNumber }</div>
          {
            postAccountNumber
              ? (
                <>
                  <div className="account_number_label label">記号番号：</div>
                  <div className="account_number_value value">{ postAccountNumber }</div>
                </>
                )
              : null
          }
          <div className="account_furigana_label label">フリガナ：</div>
          <div className="account_furigana_value value">{ furigana }</div>
          <div className="account_name_label label">口座名義</div>
          <div className="account_name_value value">{ name }</div>
          {
            comment
              ? <div className="account_comment"><small>{ comment }</small></div>
              : null
          }

        </li>
      )
    })
  }

  return (
    <aside className="payment_methods">
      <h4 className="payment_title_english">Bank Transfer</h4>
      <h4 className="payment_title_japanese accent_font_jp">お振込先</h4>
      <div className="payment_group">
        <ul className="banks">
          { _renderBankAccounts() }
        </ul>
      </div>
    </aside>
  )
}
export default memo(PaymentMethods)
