import React, { memo } from 'react'
import PropTypes from 'prop-types'

import DotLoader from '../DotLoader'
import './Button.sass'

const Button = ({ className, handleClick, label, disabled, type, icon, ...rest }) => {
  const _renderIcon = () => {
    if (icon) {
      return (
        <div className="icon_container">{icon}</div>
      )
    }
    return null
  }
  return (
    <button type={type} className={`btn ${className}`} onClick={handleClick} disabled={disabled} {...rest}>
      { _renderIcon() }
      { label }
      { disabled ? <DotLoader /> : null}
    </button>
  )
}
export default memo(Button)

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  label: PropTypes.string.isRequired
}
Button.defaultProps = {
  type: 'button'
}
