import React, { memo } from 'react'

import DocumentHeader from '../../components/Document/DocumentHeader'
import Table from '../../components/Table'
import DocumentRemarks from '../../components/Document/DocumentRemarks'
import DocumentFooter from '../../components/Document/DocumentFooter'

import './PrintDelivery.sass'

const PrintDelivery = () => {
  return (
    <section className="page delivery">
      <DocumentHeader type="delivery"/>
      <div className="main_content">
        <Table />
      </div>
      <DocumentRemarks />
      <DocumentFooter />
    </section>
  )
}
export default memo(PrintDelivery)
