import React, { PureComponent } from 'react'
import moment from 'moment'
import Picker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import PropTypes from 'prop-types'

import PickerInput from './PickerInput'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.sass'

registerLocale('ja', ja)

class DatePicker extends PureComponent {
  _handleChange = (date) => {
    // console.log(date)
    const value = moment(date).format('YYYY-MM-DD')
    this.props.handleChange(this.props.name, value)
  }

  _formatDate = () => {
    const { value } = this.props
    const formatedDate = value ? moment(value, 'YYYY-MM-DD').toDate() : null
    return formatedDate
  }

  render () {
    const { label, value, name, handleChange, yearRange, required, ...rest } = this.props
    return (
      <div className="datepicker_container">
        {
          label ? <div className={`label${required ? ' required' : ''}`}>{label}</div> : null
        }
        <div className="datepicker_inner_container flex align-center">
          <Picker
            dateFormat="yyyy年MM月dd日"
            selected={this._formatDate()}
            onChange={this._handleChange}
            locale='ja'
            className="datepicker_input"
            calendarClassName="datepicker_calendar"
            dayClassName={() => 'datepicker_day'}
            todayButton='今日'
            placeholderText="日にちを選択してください"
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={yearRange}
            customInput={<PickerInput />}
            {...rest}
          />
          <div className="picker_value">{value ? moment(value, 'YYYY-MM-DD').format('YYYY年MM月DD日') : ''}</div>
        </div>
      </div>
    )
  }
}
export default DatePicker

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  yearRange: PropTypes.number
}
DatePicker.defaultProps = {
  yearRange: 100,
  value: moment().format('YYYY-MM-DD'),
  label: ''
}
