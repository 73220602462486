import React, { memo } from 'react'

import DocumentHeader from '../../components/Document/DocumentHeader'
import Table from '../../components/Table'
import DocumentRemarks from '../../components/Document/DocumentRemarks'
import DocumentFooter from '../../components/Document/DocumentFooter'

import './PrintEstimate.sass'

const PrintEstimate = () => {
  return (
    <section className="page estimate">
      <DocumentHeader type="estimate"/>
      <div className="main_content">
        <Table />
      </div>
      <DocumentRemarks />
      <DocumentFooter />
    </section>
  )
}

export default memo(PrintEstimate)
