import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER
} from '../actions/types'

const INITIAL_STATE = {
  loading: null,
  isAuthenticated: false,
  user: null,
  error: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_USER: return { ...state, loading: true, error: null }
    case LOGIN_USER_SUCCESS: return { ...state, loading: false, error: false, isAuthenticated: true, user: action.payload }
    case LOGIN_USER_FAIL: return { ...state, loading: false, error: true }
    case LOGOUT_USER: return { ...state, ...INITIAL_STATE }
    default: return { ...state }
  }
}
